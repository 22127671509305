import React from 'react';
import './style.scss'


function PageNotFound() {
    return (<div className='contentCenter'>
        <h3>Page not found.</h3>
    </div>)
}

export default PageNotFound;