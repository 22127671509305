import { useEffect } from "react";
import Header from "../../ui-components/Header";
import Footer from "../../ui-components/Footer";
import { Typography, Box } from "@mui/material";
import AOS from "aos";
import "../Users/Welcome/style.scss";
import "./Agreement.scss";
import "aos/dist/aos.css";

const TermsAndConditions = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  });

  return (
    <div className="welcome">
      <Header />
      <div className="container">
        <Box className="agreement">
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            03/01/2023, 09:00 1/12 TERMS AND CONDITIONS OF MEMBERSHIP IN
            PELLIFIX MATRIMONY Date: Sunday(2023-01-03), Time: 09:00 AM Welcome
            to Pellifix.com (herein referred as PF). This document is an
            electronic record in terms of the Information Technology Act, 2000
            and rules there under pertaining to electronic records as applicable
            and amended. This electronic record is generated by a computer
            system and does not require any physical or digital signatures.
            <br />
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries Guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            privacy policy and the terms and conditions for access or usage of
            www.pellifix.com (the "Website").
            <br /> PLEASE READ THE FOLLOWING TERMS AND CONDITIONS VERY CAREFULLY
            BEFORE USING THE WEBSITE. ACCESSING, BROWSING OR OTHERWISE USING THE
            WEBSITE IMPLIES YOUR AGREEMENT TO BE BOUND BY ALL THESE TERMS AND
            CONDITIONS ("Agreement").
            <br /> If you do not want to be bound by the Terms and Conditions,
            you must not use the Website or pellifix.com Ltd Services.
            <br />
            The Terms and Conditions also includes the applicable policies which
            are incorporated herein by way of reference and as amended from time
            to time (the "Terms and conditions").
            <br />
            In these Terms, references to "PF member" shall mean the end user
            accessing the Website/PF services, its contents or using the PF
            Services offered. PF member also includes persons such as father,
            mother, brother, sister, relative or a friend ("Registrant") of the
            prospective bride/ bridegroom. The terms "You" and "User" shall be
            interchangeably used for "PF member". "PF Services" means a
            collective reference to the Pellifix Matrimony service provided by
            pellifix.com, GST : 37DNHPM5058M1ZC, a company under the provisions
            of companies act, 2013 and having its registered office at near
            Kalpana Gas Godowns, No.16-13-359/1, Harinathapuram ,Vidhyanagar
            Layout, Nellore, SPSR Nellore, Andhra Pradesh, 524001. Scope You
            understand and acknowledge that Pellifix Matrimony (PF) acts as an
            "Intermediary" as defined under clause (1) sub-clause (w) of Section
            2 of the Information Technology Act, 2000.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            PF is a brand owned by pellifix.Com which owns, retains and has
            complete rights in PF and the PF Website /App/ PF Service.
            <br />
            PF acts as a platform to enable any user to themselves register on
            it (by filling the mandatory fields and optional fields, if any) to
            voluntarily search for profile(s) from the database of PF's already
            registered users, for seeking prospective lawful matrimonial
            alliances for themselves.
            <br />
            PF retail store may also be able to assist you to create your
            profile, however, you must have a valid/operational mobile phone
            number and an email id.
            <br />
            The profiles in the database of PF are classified broadly on the
            basis of language and region for the ease and convenience of its
            member / customer.
            <br />
            PF Members are provided with free/paid access for searching profiles
            from the database of PF, as per the partner preference set by you
            (on the Website/Applications -(App) and you can shortlist the
            profiles in which you are interested.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            1. Eligibility :
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A) PF Membership and rights of admission is reserved solely for :
            <br />
            I. Indian Nationals & Citizens.
            <br />
            II. Persons of Indian Origin (PIO).
            <br />
            III. Non Resident Indians (NRI).
            <br />
            IV. Persons of Indian Descent or Indian Heritage
            <br />
            V. Persons who intend to marry persons of Indian Origin.
            <br />
            B) Further in capacity as PF member you confirm that you are :
            03/01/2023, 09:00
            <br />
            I. 18 years or above (if you are a woman) or 21 years or above (if
            you are a man);
            <br />
            II. If you have applied for Divorce, you may register on our
            website/App by stating "Awaiting Divorce".
            <br />
            III. If you are a resident of any other Country, you are legally
            competent to marry as per the local rules applicable to your country
            and you shall comply with the Indian laws for marrying a person of
            Indian Origin.
            <br />
            C) In case you are a Registrant of the prospective bride/ bridegroom
            and has created profile in PF Website/App on behalf of them or is
            accessing the PF Website/App on behalf of them implies that you have
            taken their consent for their profile creation in PF and for
            accessing the PF Website/App.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            2. Registration
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. We expect that you would complete the online registration process
            with fairness and honesty in furnishing true, accurate, current,
            complete information and with providing recent photos of you which
            will help you to meet your parameters . We expect you to read the
            relevant column before keying in the details or selecting the option
            available or uploading the photo. You are requested not to key in
            details of the profile in field other than the applicable field
            (including mentioning id's of other platforms/websites/App or
            repeating your details in another fields, after filling them once in
            the relevant fields or others photographs. In order to serve you
            better if PF requires additional details you agree to provide it.
            You may also give a missed call to any of our retail outlets phone
            number for us to call you back and assist you in getting your
            profile registered on our website/App. Further you may fill in your
            email id and phone number in any of our registration campaigns
            appearing in various websites as part of our advertisements, basis
            which you may receive call from our Customer Service Center and
            assist you in getting your profile registered on our website/App. PF
            may also reproduce your profile information in other matrimonial
            websites owned by PF including community based websites to provide
            better matching Profiles to you.
            <br />
            B. If at any point of time PF comes to know or is so informed by
            third party or has reasons to believe that any information provided
            by you for registration (including photos) or otherwise is found to
            be untrue, inaccurate, or incomplete, PF shall have full right to
            suspend or terminate (without any notice) your PF membership and
            forfeit any amount paid by you towards PF membership fee and refuse
            to provide PF service to you thereafter.
            <br />
            C. PF also reserves the right to block the registration of your
            profile on Website/App, if any, in the case of your contact
            details/links being entered in irrelevant fields or if there are
            errors in any data entered by the PF members in their profile.
            <br />
            D. Registration of duplicate profiles of the same person is not
            allowed in PF Website/App. PF shall have full right to suspend or
            terminate (without any notice) such duplicate profile.
            <br />
            E. You acknowledge and confirm that your registration with PF and
            the usage of PF services is with the bonafide intention of marriage
            and not otherwise. PF Membership is restricted strictly to the
            registered PF individual member only. Organizations, companies,
            businesses and/or individuals carrying on similar or competitive
            business cannot become Members of PF and nor use the PF Service or
            PF members data for any commercial purpose, and PF reserves its
            right to initiate appropriate legal action for breach of these
            obligation.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            3. Account Security
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            You are responsible for safeguarding the confidentiality of your PF
            login credentials such as your user id, password, OTP, etc., and for
            restricting access to your computer/mobile to prevent unauthorized
            access to your account. We, as a Company do not ask for Password and
            you are cautioned not to share your password to any persons. You
            agree to accept responsibility for all activities that occur under
            your account.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            4. Role and Responsibility of PF
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. PF reproduces your details once you register on our website /App
            on "as is as available" basis and also share your profile with other
            registered PF members within website(s) of MCL. 03/01/2023, 09:00
            3/12
            <br />
            B. PF's obligation is only to provide an interface to its registered
            members to search their prospect themselves without any assistance.
            <br />
            C. The profile search conducted by any PF member and the matches
            shown thereof are automatically generated by PF, and based on the
            partner preference set by you. In the event of PF member changing
            their partner preference on the Website/App, then the automated
            system generated prospect results of the Website/App may also
            undergo corresponding change.
            <br />
            D. PF does not prohibit any PF member from sending interest to your
            profile or communicating to you based on their partner preference.
            <br />
            But you are at the liberty to deny their interest or proceed further
            if you are interested. E. PF cannot guarantee or assume
            responsibility for any specific results from the use of the data
            available from the PF service or from other matrimonial websites
            owned by PF including community based websites.
            <br />
            F. PF shall safeguard sensitive user information using security
            standards, authentication mechanisms, access controls and encryption
            techniques.
            <br />
            G. PF cannot guarantee the complete protection of user data while it
            is in transit, or prevent any tampering of the data by a third party
            with malicious intent before the data reaches the PF servers.
            <br />
            H. PF has a special safety feature for women, called "Secure
            Connect", which enables women to take control of their privacy and
            safety while searching for their life partner.
            <br />
            "Secure Connect" has a calling feature that lets the women in PF
            receive calls from men who have premium membership without revealing
            their contact numbers to them, It is upto to the women to respond to
            the other party and PF has no control over the same.
            <br />
            I. PF do not authenticate/endorse any information of any profile and
            hence you as a user need to verify the credentials and information
            provided by other users.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            5. Role and Responsibility of PF Member
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. You, shall safeguard your profile information by creating a
            strong password during profile creation with combination of
            alphabets, both upper and lower case and numbers.
            <br />
            B. Any information / data required by PF for using the PF services
            shall be provided by the PF Member, as and when so sought by PF.
            <br />
            C. You are requested to verify the credentials of the prospect,
            exercise due care and caution regarding their profile information
            which includes marital status, educational qualifications, financial
            status, occupation, character, health status, etc. and satisfy
            yourself before making a choice of your match. PF shall not be
            liable for short coming due to any misrepresentations made by any of
            its PF members.
            <br />
            D. You are required to verify the credentials of the prospect and we
            shall not be liable for any misbehavior/ misrepresentations made by
            the Prospect to you during video call. Please check "Safe Matrimony"
            link in the website/Apps for guidance.
            <br />
            E. To get better search results, PF Members are expected to provide
            latest photograph which should not be more than 3 (three) months
            old.
            <br />
            Providing old photographs/ photographs of others, inaccurate / false
            information shall be treated as violation of terms and conditions
            and PF shall retain their right under clause 2 (b) of this terms and
            conditions.
            <br />
            F. PF members are expected to disclose their health records during
            profile enrollment which includes any pre-existing illness, physical
            disability etc.
            <br />
            Non - disclosure at the time of enrollment shall be treated as
            violation of the terms and conditions and PF shall retain their
            right under clause 2 (b) of this terms and conditions.
            <br />
            G. PF Members are advised to refrain from : 03/01/2023, 09:00 4/12
            <br />
            i. Entering into any financial transactions with prospects. PF
            Members shall not seek financial help or provide financial help from
            / to the other PF Members
            <br />
            ii. Using abusive language when they communicate with the other PF
            Members
            <br />
            iii. being discriminative or using racial comments etc.
            <br />
            iv. Sharing of confidential and personal data with each other but
            not limited to sharing of bank details, etc.
            <br />
            v. Entering into physical relationship with any prospect before
            marriage.
            <br />
            vi. violating any law for the time being in force.
            <br />
            vii. From mentioning details of other matrimonial services while
            sending personalized messages
            <br />
            H. PF members are expected to visit URL
            https://www.pellifix.com/safe-matrimony/ for understanding the
            safety measures on partner search and awareness towards online
            safety.
            <br />
            I. PF Members are expected to be cautious of prospects who ask for
            favours, money etc or call from multiple phone numbers, only
            interact over phone, doesn't come forward for face to face meeting
            (physically or through video calls) and don't involve family and
            friends in match making. Beware of suspended profiles status before
            you finalize an alliance with the prospect.
            <br />
            J. The PF members agrees that for getting effective search results
            of prospects on Website/App you will regularly log in to the profile
            maintained in the Website/App and send expression of interest which
            is an automated messages to prospects as a free PF member, and in
            the event you have availed paid PF package, you can send
            personalized messages to prospects to show your expression of
            interest/replies. PF member also may review the expression of
            interest sent to you / read the messages sent to you by prospects
            and may respond suitably.
            <br />
            K. You also agree that while accessing / using the Website/App, you
            will be guided by the terms and conditions of the Website/App
            including but not limited to the use of feature of website /App like
            chat instantly with prospect, viewing horoscope of prospects,
            enhancing privacy settings (photo/horoscope/phone number) or for
            viewing social and professional profile of members on their
            Facebook, LinkedIn, etc.
            <br />
            L. If the PF Member fails to update in PF website/App, any change in
            the particulars furnished in their profile/ then the PF membership
            may be suspended by PF at its sole discretion.
            <br />
            M. PF member shall, in case of finalization of his/her/their
            Registrant's marriage, delete their profile by themselves or
            intimate PF for removal/deletion of their profile.
            <br />
            N. PF Members agree to use secure devices, software and networks in
            a private place for accessing the PF services.
            <br />
            O. PF member shall not use any kind of Bots like web robots, Chatbot
            or any other automated processes/programs/scripts to use,
            communicate or respond in PF Website/App.
            <br />
            P. PF Members shall not probe, scan or test the vulnerabilities of
            the PF Website/App or any network connected to the Website/App nor
            breach the security measures instituted by PF.
            <br />
            Q. You shall also not make any payment / transfer any amount to
            account of any PF employees. All payments shall be made only to PF
            account.
            <br />
            R. PF members are expected to approach PF to resolve their
            complaints and grievances and shall not air their
            grievance/complaints in social media.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            6. Customer Care / Customer Service
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            In the event you come across any concerns in our service, then
            please be free to contact us: Email ID : vamshikrishna@pellifix.com
            03/01/2023, 09:00 5/12 Contact No : +91-9381956441 Address :
            pellifix.com near Kalpana Gas Godowns, No.16-13-359/1,
            Harinathapuram ,Vidhyanagar Layout, Nellore, SPSR Nellore, Andhra
            Pradesh, 524001
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            7. Medium of Communication to PF Members
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. PF member hereby consents for receiving communication including
            promotional communications through any medium of communications such
            as electronic mails, calls, SMS or through Whatsapp messages from PF
            or other portals owned by PF or their alliance partners.
            <br />
            B. PF by its automatic system sends to its member (s) profile (s) of
            the prospect by way of images/ documents/ messages / links.
            <br />
            PF member confirms that the mobile number submitted to it for
            verification or alternative number if any provided, is not
            registered with the Do Not Disturb / National Customer Preference
            Register and they shall not initiate any complaint. PF Member
            further confirms that even if PF Member is registered with the
            telecom service provider under the category Do Not Disturb /
            National Customer Preference Register the calls from PF either to
            the verified mobile number or alternative number if any provided
            shall not be treated as promotional calls.
            <br />
            C. You are also entitled to set up your communications frequency
            from PF, like - only on weekends or fortnightly etc.
            <br />
            You may also opt for not getting any call from PF by ticking the
            appropriate box in the Website/App, provided that, you agree to
            receive calls from Relationship Manager of PF for contacting you as
            a shortlisted profile for their registered members.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            8. Confidentiality
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            Any feedback you provide to PF shall be deemed to be
            non-confidential. PF shall be free to use such feedback/information
            on an unrestricted basis.
            <br />
            Further, by submitting the feedback, you represent and warrant that
            <br />
            (i) your feedback does not contain confidential or proprietary
            information of yourself or third parties;
            <br />
            (ii) PF member is not under any obligation of confidentiality,
            express or implied, with respect to the feedback;
            <br />
            (iii) you are not entitled to any compensation or reimbursement of
            any kind from PF for the feedback under any circumstances.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            9. Privacy of Membership
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            To protect your privacy and understand our practices as amended from
            time to time , please read and follow our Privacy Policy which also
            governs your visit to PF and other websites owned by PF, the
            personal information / data provided to us by you during the course
            of usage of PF will be treated as confidential and in accordance
            with the Privacy policy and applicable laws and regulations. If you
            object to your information being transferred or used, please do not
            use the website.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            10. Grievance Cell
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            In the event you come across any violation by another user including
            but not limited to having content in the web site that is obscene,
            menacing, grossly offensive, harming minors, infringing copyright,
            patents, etc., or another user is impersonating etc. you may then
            please be free to provide your concerns in writing or email us with
            digital signature to: Email: vamshikrishna@pellifix.com, Grievance
            Officer: Mr. M Vamshikrishna Contact Address :M/s. pellifix.com near
            Kalpana Gas Godowns, No.16-13-359/1, Harinathapuram ,Vidhyanagar
            Layout, Nellore, SPSR Nellore, Andhra Pradesh, 524001
            <br />
            The Grievance office shall be available between 10 am till 6 pm
            Indian Standard Time from Monday to Saturday excluding Sunday and
            public holidays in India. The Grievance officer is appointed as per
            Rule 3 03/01/2023, 09:00 6/12 (11) of The Information Technology
            (Intermediaries Guidelines) Rules, 2011. Any and all complaints to
            the Grievance Officer shall be in writing The Grievance Officer
            shall handle complaints in relation to the following violation by
            the User of computer resource as the User is not entitled to host,
            display, upload, modify, publish, transmit, update or share any
            information on the Website/App that -<br />
            A. belongs to another person and to which the User does not have any
            right to;
            <br />
            B. is grossly harmful, harassing, blasphemous defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatever;
            <br />
            C. infringes any patent, trademark, copyright or other proprietary
            rights;
            <br />
            D. violates any law for the time being in force;
            <br />
            E. deceives or misleads the addressee about the origin of such
            messages or communicates any information which is grossly offensive
            or menacing in nature;
            <br />
            F. impersonate another person;
            <br />
            G. contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
            <br />
            H. threatens the unity, integrity, defence, security or sovereignty
            of India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            11. Disputes between Members
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. PF Members are solely responsible for the communications (through
            any medium) with prospect or vice versa. PF expressly disclaims any
            responsibility or liability for any monetary transaction(s) or
            exchange(s) or interaction(s) or passing of information(s) etc.
            between any PF members interest via e-mail, chat, interaction,
            Whatsapp or any other medium of communication between PF members
            either using website /App or otherwise.
            <br />
            B. PF has no obligation, to monitor any such disputes arising
            between the PF members, and PF shall not be party to any such
            dispute/litigation etc.
            <br />
            C. PF is not a broker or the agent of any PF member, and PF does not
            partake in the exchange of any kind of discussion between the PF
            members and prospects or the results of their discussion.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            12. Content Right
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. You agree that all content of PF belong to PF excluding your or
            third party content including advertisement on the Website/App for
            which PF has requisite license/right in terms hereof to display the
            same on our Website/App.
            <br />
            B. You acknowledge that you alone are responsible and liable for the
            content (mandatory or optional content you provided on our website)
            and information including the profile details, photograph and other
            content(s) keyed in the relevant field of the Website/App at the
            time of the creation of the PF profile or subsequently modified by
            you and also for all the post and communication (including
            personalized messages sent by PF paid members) with other member(s).
            <br />
            You hereby represent and warrant that you own all rights, title and
            interest in your content/information or have the necessary licenses,
            rights, consents, and permissions to provide the said
            content/information. However, by submitting the content/information
            to PF, you hereby grant PF a worldwide, non-exclusive, royalty-free,
            sub-licensable and transferable license to use, reproduce,
            distribute, prepare derivative works of, display, publish,
            communicate to the public, law enforcement agencies, courts, and the
            PF's business and services, including without limitation for
            promoting, 03/01/2023, 09:00 7/12 communicating to the public and
            redistributing part or all of the content/information (and
            derivative works thereof) in any media formats and through any media
            channels.
            <br />
            C. While PF does not accept any obligation to monitor the
            content/information in your profile, if the content/information is
            found not to be in compliance with these Terms and conditions, PF
            may delete the content/information and / or terminate or suspend
            your registration or (without a refund of any subscription paid by
            you in terms of clause 2 (b) of these terms and conditions). You
            agree that if the situation warrants PF may at its sole discretion
            put restriction on any PF member to other member's communications.
            <br />
            D. PF reserves the right to proceed/initiate appropriate steps under
            the prevailing law against infringement by violators of the its
            proprietary rights or for illegally copying, modifying, publishing,
            transmitting, distributing, performing, displaying, or selling of
            any such proprietary information including using it for commercial
            purpose in any manner whatsoever.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            13. Third Party website
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. PF member understand and agree that PF may allow third parties to
            put up advertisements on the PF application/Website and PF does not
            control the contents of such third party advertisement on the PF
            application/Website. Third party websites to which links are given
            on Website/App are not governed by these Terms and conditions and
            all such third party websites are governed by their own terms and
            conditions for use and privacy policy, and in case of conflict the
            terms and conditions of the third party website shall prevail. The
            Company does not in any manner whatsoever authenticate, endorse,
            verify or certify these third party websites or any contents
            therein. Please note that a user shall click on links to third party
            websites at his / her sole risk and responsibility.
            <br />
            B. The PF members agrees and understands that PF may also allow
            third parties to provide PF members services on the website/App like
            horoscope matching etc., and PF may also collect the payment for
            such third party services to you, and PF in this regard can have
            revenue sharing arrangement with such third party, however in no
            event shall PF be liable to you for such third party services.
            <br />
            C. PF reserves the right to add/delete/alter/modify/suspend any or
            all the PF Services at any time as it may deem fit, without notice.
            The PF members are expected to see the change on the Website/App.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            14. Limitation of liability
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. PF / PF or its Office bearers shall under no circumstances be
            liable or responsible to the PF member or his/her authorized
            Representative or Registrant or any third party for any direct,
            indirect, special, exemplary, incidental, or consequential damages
            of any character including, without limitation, damages resulting
            from the use of our Website/App/Third Party Website/ PF services.
            <br />
            B. PF informs you that the exchange of profile(s) through or by PF
            should not in any way be construed as a matrimonial offer and/or
            recommendation and / or advice or guarantee given to the PF member,
            from/ or by PF.
            <br />
            C. Notwithstanding anything to the contrary contained herein, PF's
            liability to you for any cause whatsoever, and regardless of the
            form of the action, will at all times be limited to the amount paid,
            if any, by you to PF, for any specific PF paid package, and no
            further.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            15. PF / PF
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            PF / PF will not be liable in case of any wrong/improper match made
            due to unavailability of profiles from PF/PF's owned other websites.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            16. PF / PF
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            PF / PF or its office bearers shall under no circumstances be
            liable, if any, for the PF member entering into financial
            transaction with any other PF Member or any third party.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            17. MCL / PF
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            MCL / PF or its office bearers shall under no circumstances be
            liable, if any, for any PF members not responding/reciprocating when
            you approach them for matrimonial alliance
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            18. Class Action Suits
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            You acknowledge and confirm that you will not bring or participate
            in any class action or other class proceeding in connection with any
            dispute with PF. Further neither you nor PF agrees to class
            arbitration. 03/01/2023, 09:00 8/12
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            19. General
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            In the event you file a false complaint against another prospect on
            our Website /App and consequently we have suspended/deleted that
            prospects profile based on your complaint, then we reserve our right
            to initiate appropriate legal (Civil/Criminal) action against you
            and claim any and all costs expenses from you, for such
            irresponsible/misrepresentation/illegal/unlawful action. We also
            reserve our right to suspend your profile and forfeit any and all
            amounts paid by you for the PF services as per clause 2 (b) of these
            terms and conditions.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            20. Notwithstanding anything contained herein, PF reserves the
            absolute right to delete, in any manner as it deems fit, any content
            of any profile listing placed on PF/website/App (once such instance
            come to PF notice) in order to ensure, that proper consent has been
            obtained by you , prima facie accuracy and the prevailing laws in
            force for the time being, especially those relating to providing any
            obscene, libelous, blasphemous, slanderous, defamatory or invasive
            of another person's (deceased or alive) right of privacy or
            publicity, or that may reasonably be deemed to be harmful, vulgar,
            pornographic, abusive, harassing, threatening, hateful,
            objectionable with respect to race, religion, creed, nationality,
            gender or otherwise unfit for reproduction; or suggests or
            encourages unfair or illegal /indecent, unlawful activity. PF also
            reserves the right to block/ delete / suspend the profile which
            might be offensive, illegal or that might violate the rights, harm
            or threaten the safety of our office bearers/employees (including
            undue communication with any employee) and/or other registered
            prospects or using our PF website/App as a means of communication
            while sending messages to other PF members regarding their profile
            identity in other matrimonial websites.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            21. Once your paid membership expires, you cannot avail the
            unexpired balance phone call count/ unexpired SMS . Similarly, you
            cannot access the already viewed PF member(s) contact information
            unless you renew your account within 30 days.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            22. However on renewal, the unexpired phone call / SMS shall be
            carried forward to your account from the last day of expiry.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            23. In case of conflict between the terms and condition of
            Website/App and terms and conditions of any other website including
            other websites of MCL, the terms and condition of PF Website/App
            shall prevail for the service provided through this Website/App.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            24. Disclaimer
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. Your access to and use of the PF Services or any content is at
            your own risk. YOU UNDERSTAND AND AGREE THAT THE PF SERVICES ARE
            PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WITHOUT
            LIMITING THE FOREGOING, TO THE FULL EXTENT PERMITTED BY LAW, PF
            DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. PF does not
            warrant that the Website/App, its servers, or e-mail sent from PF
            are free of viruses or other harmful components. PF will not be
            liable for any damages of any kind arising from the use of this PF
            Website/App, including, but not limited to direct, indirect,
            incidental, punitive, and consequential damages.
            <br />
            B. PF does not give any implied or explicit guarantee or warranty of
            marriage or alliance by you choosing to register on our Website/App
            and using PF services (both paid and free).
            <br />
            C. Notwithstanding anything contrary contained anywhere, under no
            circumstances, PF shall be held responsible or liable whatsoever or
            howsoever, arising out of, relating to or connected with:
            <br />
            i. any act or omission done by PF/MCL/payment gateway/alliance
            partner etc.;
            <br />
            ii. any untrue or incorrect information submitted by you or on your
            behalf;
            <br />
            iii. any decision taken by you or on your behalf or any consequences
            thereof, based on any information provided by any other user
            (including suspension/deletion of the profile from PF);
            <br />
            iv. any unauthorized or illegal act done by any third party relating
            to or connected with any information submitted by you or on your
            behalf;
            <br />
            v. any cyber crime attempted or committed by anyone and
            <br />
            vi. any incident of force-majeure or 'act of god'. 03/01/2023, 09:00
            9/12
            <br />
            vii. Any issue already stated in these terms and conditions
            including limitation of liability clause of these terms and
            conditions.
            <br />
            viii. Using/availing of third party services namely horoscope
            matching services etc. and for paying them through the Website.
            <br />
            ix. PF shall not be liable for the outcome of during any interaction
            in a meeting, call, sms, chat, email or social media posts at any
            point of time
            <br />
            x. Any issues relating to any technical malfunction of any
            telecommunication network, software, hardware failures, network
            congestion, denial of service, failure due to spamming or any
            combination of the above.
            <br />
            D. You expressly agree that your use of this Website/App is at your
            sole risk.
            <br />
            E. We are not liable to you for any damage or alteration to your
            equipment including but not limited to computer equipment, hand-held
            device or mobile telephones as a result of the installation or use
            of the app. We are not liable to you for any damage or alteration to
            your equipment including but not limited to computer equipment,
            hand-held device or mobile telephones as a result of the
            installation or use of the app.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            25. Indemnity
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            By using our PF services you agree to defend, indemnify, and hold
            harmless PF, its subsidiaries, affiliates, Directors, officers,
            agents, and other partners and employees, fully indemnified and
            harmless from any loss, damage, liability, claim, or demand,
            including reasonable attorney's fees, made by any person through
            improper use of the service provided by PF. This defence and
            indemnification obligation will survive in perpetuity.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            26. Termination
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. We/ PF in good faith reserve our right to terminate your PF
            membership or suspend/delete your profile at our sole discretion
            without any notice to you and take any action as mentioned in clause
            2 (b) of the Terms and Conditions, in case you
            <br />
            I. seek physical / financial favors from our other registered
            members or
            <br />
            II. have provided incorrect information on our website/App or
            <br />
            III. have committed any fraudulent/ unlawful/ illegal activities
            through the use of our website/App or
            <br />
            IV. have violated this Terms and conditions or
            <br />
            V. have misappropriated the name, likeness, email address, contact
            no or other personally identifiable information of another PF member
            created a profile on our website /App by impersonation/ fake/ bogus/
            false/ misrepresentation/ without consent of the person who's
            profile is being registered or use only part information including
            using photo of third parties without the permission of such third
            parties or act with other members/employees in indecent/improper
            manner,
            <br />
            B. If PF receives any complaint against you and consequently we have
            suspended/deleted your profile in good faith, then we shall not be
            liable to you in any manner whatsoever, including for any loss,
            costs, expenses, or consequence, if any.
            <br />
            C. Unless otherwise provided in these Terms and Conditions, PF
            membership may be terminated by PF / you without assigning any
            reasons by serving 7 ( seven) days written notice on the other, all
            amount paid till date by you to PF shall be forfeited.
            <br />
            D. Upon termination / suspension of PF membership, your right to use
            or access the PF Website/App PF services will cease immediately. All
            provisions of this terms and conditions that by their nature should
            survive termination shall survive termination, including, without
            limitation, disclaimers, and limitations of liability. Termination
            of your access to and use of the PF Services shall not relieve you
            of any obligations arising or accruing prior to such termination.
            03/01/2023, 09:00 10/12
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            27. Jurisdiction and Applicable Law
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. The registration of PF membership and all PF services are deemed
            to have been entered into within the territorial Jurisdiction of
            Nellore, India.
            <br />
            B. The PF Members unconditionally agree that all such disputes and /
            or differences if any shall be governed by the Laws of India and
            submitting to the exclusive Jurisdiction of appropriate court of law
            in nellore, Andhra Pradesh , India.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            28. Mode of Payment
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. Payment made by cash / cheque / Net Banking / Debit / Credit
            Card/ Paypal/ RTGS/ NEFT/ would be in accordance with the prevailing
            RBI guidelines. Activation of paid PF service shall be subject to
            realization of the said payment. In case of dishonor of
            Cheque/demand draft; you shall be liable to pay applicable bank's
            cheque/DD bouncing charges. Further PF service to your account will
            be deactivated / suspended until you settle the value of the payment
            and applicable bank charges in cash.
            <br />
            B. PF uses third party payment gateways for collection payment
            through credit/debit card or other payment instruments. You agree to
            provide us with your Permanent Account Number / Form 60/61 of the
            Income Tax Act, 1961 and rules thereunder in the event you choose
            any paid package of MCL, the value of such paid package being
            Rs.2,00,000/- [Rupees Two Lakh Only] and above from MCL. You agree
            to provide us with your Permanent Account Number / Form 60/61 of the
            Income Tax Act, 1961 and rules thereunder in the event you choose
            any paid package of MCL, the value of such paid package being
            Rs.2,00,000/- [Rupees Two Lakh Only] and above from MCL.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            29. Refund and Assignment
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. The payments made by any PF member to MCL by way of membership /
            renewal fee / auto renewal are treated as non-refundable.
            <br />
            B. PF member shall not assign or transfer your PF membership to any
            other person or entity, and such an act shall be treated as
            violation of terms and conditions and PF shall retain their right
            under clause 2 (b) of this terms and conditions. C. Payment once
            made for PF services cannot be assigned to any person/party or
            adjusted towards any other product or packages provided by the
            Company.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            30. Renewal
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. We automatically renew all paid Memberships before the expiry of
            the term of the paid Membership. You agree that your account will be
            subject to this automatic renewal feature
            <br />
            B. When you first subscribe for your paid Membership, you
            acknowledge that when your paid Membership expires it will
            automatically renew for the duration and at the last paid price.
            <br />
            C. The maximum tenure of any packages will be 12 (Twelve) month.
            Every PF packages post validity period (varies from package to
            package), shall be renewed.
            <br />
            D. If you do not want your paid membership to automatically renew,
            you must cancel your paid membership. In case you have to mail for
            cancellation, mail to admin@pellifix.com
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            31. Additional terms and conditions applicable to Non-Resident
            Indian customers.
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A. Jurisdiction The laws of India shall be applicable.
            <br />
            B. Arbitration of Disputes. 03/01/2023, 09:00 11/12 The Parties
            shall discuss among themselves and resolve any disputes informally.
            We are interested in attempting to resolve any Disputes by amicable
            and informal means, and we encourage you to contact us before
            resorting to arbitration. If the dispute is not resolved within 30 (
            thirty ) days from the date of such dispute , then such dispute may
            only be resolved through an individual arbitration conducted in
            accordance with the provisions of The Arbitration and conciliation
            Act, 1996, by sole arbitrator. The seat of Arbitration shall be
            Nellore, Andhra Pradesh, India. We shall appoint the sole
            arbitrator. The arbitration proceedings shall be conducted in the
            English language
            <br />
            C. However, Disputes relating to infringement of our intellectual
            property rights, which we may elect to have resolved by means other
            than arbitration.
            <br />
            D. In case of Renewal, Customer is charged the current price that
            prevails and renewed is done only for 3 months duration irrespective
            of the past package.
            <br />
            E. Enforcement Any proceeding to enforce this arbitration agreement
            may be brought in Courts of Nellore, Andhra Pradesh India.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            32. Moneyback Guarantee
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            Within the first 90 (ninety) days of becoming a premium member, If
            you have sent a maximum of 10 (ten) messages to mutually matching
            profiles, and there has not been a single Acceptance from your
            contacted profiles, we would refund your premium amount paid within
            a period of 7(seven) working days from such intimation to us. The
            conditions for getting a refund also includes that your profile
            should be at least 95% complete and Messages sent by you should be
            given a minimum of 2(two) weeks window for getting a response.
            Acceptance also includes
            <br />
            A.Users whose contact numbers are viewed by you for getting their
            acceptance over call/ personal message (or)
            <br />
            B.Users who have expressed interest for your profile and you have
            responded positively (or)
            <br />
            C.Users who have sent a message and you have accepted it or replied
            to it (or)
            <br />
            D.Users who have accepted or replied to the message sent by you (or)
            <br />
            E.Users who have viewed your contact number This Moneyback Guarantee
            is applicable for the purchase of Gold/Diamond/Platinum packs only.
          </Typography>

          <Typography variant="h6" component="h2" data-aos="fade-up">
            33. Assisted Service Guarantee is applicable only for the users:
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            A.Who have availed the Assisted Service for the first time.
            <br />
            B.Who have more than 100 matching profiles based on the partner
            preference set by them at the time of the Assisted Welcome Call.
            <br />
            C.Who have not narrowed down the partner preference, once the
            Assisted service starts.
          </Typography>
          <Typography variant="h6" component="h2" data-aos="fade-up">
            34. Membership Packages
          </Typography>
          <Typography variant="subtitle2" component="p" data-aos="fade-up">
            {" "}
            A.We have various premium membership packages called Gold, Diamond,
            Platinum, Prime Gold, Prime Diamond, Prime Platinum and Assisted.
            <br />
            B.You shall opt for any of our premium membership packages varying
            between three months to one year with differing price to access
            various premium features. 03/01/2023, 09:00 12/12
            <br />
            C.Each premium package has certain limitations on count of Phone
            number view except for Diamond and Platinum package (3,6,12 months).
            The Diamond and Platinum package (3,6,12 months) has unlimited phone
            number view but within the Fair Usage Policy limit. Viewing an
            accepted member's mobile number will not deduct the mobile number
            count (For applicable domains)
            <br />
            D.The members of Platinum package (3,6,12 months) can receive
            Personalised Messages (PMs) from Free Members. Only if PP of
            Platinum member matches with the Personal Interest of the free
            member profile this will be allowed (For applicable domains).
            <br />
            E.The Fair Usage Policy (FUP) limit on Phone view count and Count on
            Personal message for all the premium membership packages are as
            follows: Fair Usage Metrics Fair Usage Metrics Personal Message
            Count Phone View Count Day Zero Paid Limit 50 20 Daily Limit 100 30
            Weekly Limit 150 50 Monthly Limit 500 100 Total Limit 4000 750
            <br />
            F.To know the complete details on various Membership package and its
            benefits, please visit 'Payment Options' page Date:
            Sunday(2023-01-03), Time: 08:00 AM
          </Typography>
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
